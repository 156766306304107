.dashboard {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard h1 {
  color: #ffffff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.connect-wallet {
  background: linear-gradient(to right, #8f37eb, #3b82f6, #e91c63);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.connect-wallet:hover {
  background: linear-gradient(to right, #3b82f6, #e91c63);
}

.log-out,
.log-in {
  margin-right: 5px;
}

.fas {
  color: #ffffff;
}

nav {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

nav button {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

nav button.active {
  background: linear-gradient(to right, #8f37eb, #3b82f6, #e91c63);
  border: none;
  font-weight: bold;
}

nav button:hover {
  background: linear-gradient(to right, #8f37eb, #3b82f6);
  border: none;
}

.overview-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background-image: url(../assets/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
}

.stat-card i {
  font-size: 2em;
  margin-bottom: 10px;
}

.project-roadmap {
  /* background: linear-gradient(to right, #8f37eb, #3b82f6, #e91c63); */
  background-image: url(../assets/bg3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.issues-discovered,
.project-progress,
.projects-list {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.projects h2 {
  color: white;
}

.todo-list ul {
  list-style-type: none;
  padding: 0;
}

.todo-list li {
  background-color: #f8f9fa;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
}

.status.draft {
  background-color: #ffc107;
}

.status.urgent {
  background-color: #dc3545;
  color: white;
}

.status.on.process {
  background-color: #28a745;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
}

.progress-bar {
  background-color: #e9ecef;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  background-color: #007bff;
  height: 100%;
}

.flex-container {
  display: flex;
  gap: 20px;
  margin: auto;
}

.todo-list,
.activity-list {
  flex: 1;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.todo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.todo-header input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 60%;
}

.todo-stats {
  font-size: 0.9em;
  color: #666;
}

.sorting {
  color: #007bff;
  margin-left: 10px;
}

.todo-list ul,
.activity-list ul {
  list-style-type: none;
  padding: 0;
}

.todo-list li {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.todo-list li>div {
  flex-grow: 1;
  margin: 0 10px;
}

.todo-list h4 {
  margin: 0 0 5px 0;
}

.todo-list p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

.todo-list i {
  margin-right: 5px;
}

.status {
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.status.draft {
  background-color: #e9ecef;
  color: #495057;
}

.status.urgent {
  background-color: #ffc107;
  color: #000;
}

.status.on-process {
  background-color: #17a2b8;
  color: #fff;
}

.add-task {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  font-size: 1em;
  margin-top: 10px;
}

.activity-list li {
  display: flex;
  margin-bottom: 20px;
}

.activity-date {
  min-width: 100px;
  text-align: right;
  margin-right: 15px;
}

.activity-date p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

.activity-content h4 {
  margin: 0 0 5px 0;
}

.activity-content p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

/* Add icons for activity items */
.activity-content::before {
  content: "\f015";
  /* You can change this to the appropriate Font Awesome icon code */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
  color: #007bff;
}

.projects-table {
  width: 100%;
  border-collapse: collapse;
  color: white;
}

.projects-table th,
.projects-table td {
  color: #ffffff;
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 8px;
  background-color: #4caf50;
}

/* Financials styles */
.financials {
  color: white;
}
.financial-charts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.revenue-chart,
.expenses-chart {
  width: 48%;
}

.token-metrics ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.token-metrics li {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

/* Members styles */
.members {
  color: white;
}
.members-table {
  width: 100%;
  border-collapse: collapse;
}

.members-table th,
.members-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.contribution-score {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.score-bar {
  height: 8px;
  background-color: #2196f3;
}

.contribution-score span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
}

/* Resources styles */
.resources {
  color: white;
}
.resources-table {
  width: 100%;
  border-collapse: collapse;
}

.resources-table th,
.resources-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

/* Responsive design */
@media (max-width: 768px) {
  .financial-charts {
    flex-direction: column;
  }

  .revenue-chart,
  .expenses-chart {
    width: 100%;
    margin-bottom: 20px;
  }

  .token-metrics ul {
    flex-direction: column;
  }

  .token-metrics li {
    margin-bottom: 10px;
  }
}